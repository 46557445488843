import React from "react";
import certificate1 from "../../Assets/certifications/Certificate1.jpg";
import certificate2 from "../../Assets/certifications/Certificate2.jpg";
import certificate3 from "../../Assets/certifications/Certificate3.jpg";
import './Certification.css';

function Certification() {
  return (
    <>
      
      <div className="cert-container">
        <div className="cert-card">
          <img src={certificate1} alt="Certificate 1" />
        </div>
        <div className="cert-card">
          <img src={certificate2} alt="Certificate 2" />
        </div>
        <div className="cert-card">
          <img src={certificate3} alt="Certificate 3" />
        </div>
      </div>
    </>
  );
}

export default Certification;
